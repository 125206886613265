import { Asset } from './Asset';
import { BillingAccount } from './Billing';
import { Car, CarCatalog, CarMaker } from './Car';
import { ChargingStation } from './ChargingStation';
import { ChargingStationCertificate } from './ChargingStationCertificate';
import { Company } from './Company';
import { LogAction } from './Log';
import PricingDefinition from './Pricing';
import { RefundReport } from './Refund';
import { Site } from './Site';
import { SiteArea } from './SiteArea';
import { Subscription } from './Subscription';
import { Tag } from './Tag';
import { User } from './User';
import { UserGroup } from './UserGroup';

export interface Image {
  id: string;
  image: string;
}

export interface Images {
  id: string;
  images: string[];
}

export interface Logo {
  id: string;
  logo: string;
}

export interface SubjectInfo {
  action: string;
  data: {
    id: string;
    type: string;
  };
}

export enum DocumentType {
  PDF = 'pdf',
}

export enum DocumentEncoding {
  BASE64 = 'base64',
}

export interface TabDefiniton {
  hash: string;
  visible: boolean;
}

export interface KeyValRef<K, V, R> {
  key: K;
  value: V;
  objectRef?: R;
}

export interface KeyVal<K, V> {
  key: K;
  value: V;
}

export interface KeyValue {
  key: string | string[] | number;
  value: string;
  objectRef?:
    User |
    Subscription |
    PricingDefinition |
    SiteArea |
    Site |
    Company |
    Car |
    CarCatalog |
    Asset |
    RefundReport |
    ChargingStation |
    CarMaker |
    LogAction |
    Tag |
    BillingAccount |
    UserGroup |
    ChargingStationCertificate;
  readonly?: boolean;
  custom?: boolean;
  icon?: string;
  tooltip?: string;
}

export interface FilterParams {
  [param: string]: string | string[];
}

export interface CustomButton {
  id: string;
  name: string;
  color?: 'primary'|'warn';
  cancelButton?: boolean;
  validateButton?: boolean;
}

export enum ButtonAction {
  ACTIVATE = 'activate',
  DEACTIVATE = 'deactivate',
  EDIT = 'edit',
  OPEN_IN_MAPS = 'open_in_maps',
  MORE = 'more',
  DELETE = 'delete',
  DELETE_MANY = 'delete_many',
  REFRESH = 'refresh',
  SYNCHRONIZE = 'synchronize',
  AUTO_REFRESH = 'auto_refresh',
  OVERVIEW = 'overview',
  VIEW_ALL = 'view_all',
  EXPORT = 'export',
  ADD = 'add',
  CREATE = 'create',
  COPY = 'copy',
  MULTI_COPY = 'multi_copy',
  MULTI_CREATE = 'multi_create',
  NO_ACTION = 'block',
  OPEN = 'open',
  OPEN_URL = 'open_url',
  REGISTER = 'register',
  REMOVE = 'remove',
  RESET_FILTERS = 'reset_filters',
  REVOKE = 'revoke',
  SEND = 'send',
  SETTINGS = 'settings',
  START = 'start',
  STOP = 'stop',
  UNREGISTER = 'unregister',
  VIEW = 'view',
  INLINE_SAVE = 'inline_save',
  DOWNLOAD = 'download',
  TEST_CONNECTION = 'test_connection',
  IMPORT = 'import',
  VIEW_PRICING_DEFINITIONS = 'pricing_definitions',
  TRIGGER_MESSAGE_ACTIONS = 'trigger_message_actions',
  TRIGGER_MESSAGE = 'trigger_message',
  OK = 'ok',
  CANCEL = 'cancel',
  YES = 'yes',
  NO = 'no',
  SAVE_AND_CLOSE = 'save_and_close',
  DO_NOT_SAVE_AND_CLOSE = 'do_not_save_and_close',
}

export enum ButtonActionColor {
  BASIC = '',
  PRIMARY = 'primary',
  ACCENT = 'accent',
  WARN = 'warn',
}

export enum ChipType {
  PRIMARY = 'chip-primary',
  DEFAULT = 'chip-default',
  INFO = 'chip-info',
  SUCCESS = 'chip-success',
  DANGER = 'chip-danger',
  WARNING = 'chip-warning',
  GREY = 'chip-grey',
}

export enum LevelText {
  INFO = 'text-success',
  DANGER = 'text-danger',
  WARNING = 'text-warning',
}

export enum RestResponse {
  SUCCESS = 'Success',
}
