import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChargingProfileSource } from 'types/ChargingProfile';

@Pipe({ name: 'appChargingProfileSource' })
export class AppChargingProfileSourcePipe implements PipeTransform {
  // eslint-disable-next-line no-useless-constructor
  public constructor(
    public translateService: TranslateService,) {
  }

  public transform(chargingProfileSource: ChargingProfileSource): string {
    switch (chargingProfileSource) {
      case ChargingProfileSource.MANUAL:
          return this.translateService.instant('chargers.charging_profiles.source_manual');
        case ChargingProfileSource.SMART_CHARGING:
          return this.translateService.instant('chargers.charging_profiles.source_smart_charging');
        case ChargingProfileSource.STATIC_LIMITATION:
          return this.translateService.instant('chargers.charging_profiles.source_static_limitation');
    }
    return this.translateService.instant('chargers.charging_profiles.source_unknown');
  }
}
